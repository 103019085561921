import { Controller } from "@hotwired/stimulus"
import mrujs from "mrujs"
import CableReady from "cable_ready"

export default class extends Controller {
  static values = {
    id: Number,
    organizationId: Number,
    redirectUrl: String
  }

  connect() {
    this.element.addEventListener("dragover", this.dragOver.bind(this))
    this.element.addEventListener("drop", this.drop.bind(this))
    this.element.addEventListener("dragleave", this.dragLeave.bind(this))
  }

  dragOver(event) {
    event.preventDefault()
    this.element.classList.add("bg-info")
  }

  dragLeave(_event) {
    this.element.classList.remove("bg-info")
  }

  drop(event) {
    event.preventDefault()
    this.element.classList.remove("bg-info")

    const dataTransferString = event.dataTransfer.getData("application/json")

    if (dataTransferString) {
      try {
        const dataTransferObject = JSON.parse(dataTransferString)

        const documentId = dataTransferObject.documentId

        this.updateDocumentOwnership(documentId)
      } catch (error) {
        console.error("Failed to parse dataTransfer data:", error)
      }
    } else {
      console.error("No data found in dataTransfer.")
    }
  }

  updateDocumentOwnership(documentId) {
    const data = {
      folder_id: this.idValue,
      document_id: documentId,
      redirect_url: this.redirectUrlValue
    }

    const url = `/organizations/${this.organizationIdValue}/documentables/update_ownership`

    mrujs
      .fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })
      .then(r => r.json())
      .then(CableReady.perform)
  }
}
